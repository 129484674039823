'use client';

import { useEffect, useMemo, useState } from 'react';
import { ToastContext } from './toast-context';
import { Icon } from './icon';
import { Button } from './button';
import clsx from 'clsx';
import { Link } from './link';
import { twMerge } from 'tailwind-merge';
type ToastProviderProps = {
  children: React.ReactElement;
};

type ToastType = {
  id: string;
  message: string;
  icon: string;
  className?: string;
  withTitle?: boolean;
  withTitleIconClass?: string;
  title?: string;
  linkTitle?: string;
  url?: string;
  titleClassName?: string;
  messageClassName?: string;
  linkClassName?: string;
  iconSize?: number;
  linkIconSize?: number;
};

interface ToastInterface {
  message: string;
  icon: string;
  duration?: number;
  close: () => void;
  className?: string;
  withTitle?: boolean;
  withTitleIconClass?: string;
  title?: string;
  linkTitle?: string;
  url?: string;
  titleClassName?: string;
  messageClassName?: string;
  linkClassName?: string;
  iconSize?: number;
  linkIconSize?: number;
}

let count = 0;

function genId() {
  count = (count + 1) % Number.MAX_SAFE_INTEGER;
  return count.toString();
}

export default function Toast({
  message,
  icon,
  close,
  duration = 3000,
  className,
  withTitle = false,
  withTitleIconClass,
  title,
  linkTitle,
  url,
  titleClassName,
  messageClassName,
  linkClassName,
  iconSize,
  linkIconSize
}: ToastInterface) {
  useEffect(() => {
    const timer = setTimeout(() => {
      close();
    }, duration);

    return () => clearTimeout(timer);
  }, [close, duration]);

  return (
    <>
      {!withTitle ? (
        <div
          className={twMerge(
            clsx(
              'fixed  bottom-4 left-0 right-0 z-[100] m-auto w-full min-w-[343px] max-w-fit animate-slideInUp',
              'lg:bottom-auto lg:top-[229px] lg:mr-[72px] lg:min-w-[400px] lg:animate-slideInDown',
              { 'lg:!top-14': window?.scrollY > 25 },
              className
            )
          )}
        >
          <div className="flex items-center gap-1  bg-primary p-4 lg:p-6">
            {icon && <Icon name={icon} size={32} className="text-secondary" />}
            <p
              className={twMerge(
                'text-base font-medium leading-[1.5] text-white lg:text-lg',
                messageClassName
              )}
            >
              {message}
            </p>
            <Button onClick={() => close()} className="ml-auto !h-8 !w-8 p-0">
              <Icon name="close" size={32} className="text-white" />
            </Button>
          </div>
          {linkTitle && (
            <div className="px-4 pb-4 pt-2 text-sm lg:px-6 lg:pb-6 ">
              <Link
                href={url}
                className={twMerge('flex items-center', linkClassName)}
              >
                <span className="text-sm">{linkTitle}</span>{' '}
                <Icon
                  name="arrow-right"
                  size={linkIconSize || 32}
                  className="text-primary"
                />
              </Link>
            </div>
          )}
        </div>
      ) : (
        <div
          className={twMerge(
            clsx(
              'fixed  bottom-4 left-0 right-0 z-[100] m-auto w-full max-w-[400px] animate-slideInUp gap-1 border border-lightBlue bg-white',
              'lg:bottom-auto lg:top-[229px] lg:mr-[72px] lg:max-w-[400px] lg:animate-slideInDown',
              { 'lg:!top-14': window?.scrollY > 25 },
              className
            )
          )}
        >
          <div className="flex items-center px-4 pb-2 pt-4 lg:px-6 lg:pt-6">
            {icon && (
              <Icon
                name={icon}
                size={iconSize || 28}
                className={clsx(withTitleIconClass, 'text-secondary')}
              />
            )}
            <p
              className={twMerge(
                'ml-1 text-base font-medium leading-[1.5] text-primary lg:text-lg',
                titleClassName
              )}
            >
              {title}
            </p>
            <Button
              onClick={() => close()}
              className="ml-auto !h-8 !w-8 !border-none bg-transparent p-0 hover:bg-transparent"
            >
              <Icon
                name="close"
                size={32}
                className={twMerge('text-primary', titleClassName)}
              />
            </Button>
          </div>

          <div className="flex flex-col gap-2 px-4 text-sm lg:px-6">
            <div className={twMerge('pt-2 lg:pb-4 lg:pt-4', messageClassName)}>
              {message}
            </div>
            {linkTitle && (
              <div className="text-sm lg:pb-4 ">
                <Link
                  href={url}
                  className={twMerge('flex items-center', linkClassName)}
                >
                  <span className="text-sm underline">{linkTitle}</span>{' '}
                  <Icon
                    name="arrow-right"
                    size={linkIconSize || 32}
                    className={twMerge('text-primary', linkClassName)}
                  />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function ToastProvider({ children }: ToastProviderProps) {
  const [toasts, setToasts] = useState<ToastType[]>([]);

  function openToast({
    message,
    className,
    icon = 'mood-smile',
    withTitle,
    title,
    linkTitle,
    url,
    titleClassName,
    messageClassName,
    linkClassName,
    iconSize,
    withTitleIconClass,
    linkIconSize
  }: {
    message: string;
    className?: string;
    icon: string;
    withTitle?: boolean;
    title?: string;
    linkTitle?: string;
    url?: string;
    titleClassName?: string;
    messageClassName?: string;
    linkClassName?: string;
    iconSize?: number;
    withTitleIconClass?: string;
    linkIconSize?: number;
  }) {
    const newToast = {
      id: genId(),
      icon,
      message,
      className,
      withTitle,
      title,
      linkTitle,
      url,
      titleClassName,
      messageClassName,
      linkClassName,
      iconSize,
      withTitleIconClass,
      linkIconSize
    };
    setToasts((prevToasts) => [...prevToasts, newToast]);
  }

  function closeToast(id: string) {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }

  const contextValue = useMemo(
    () => ({
      open: openToast,
      close: closeToast
    }),
    []
  );
  return (
    <>
      <ToastContext.Provider value={contextValue}>
        {toasts &&
          toasts.map((toast) => (
            <Toast
              key={toast.id}
              message={toast.message}
              icon={toast?.icon}
              close={() => closeToast(toast.id)}
              className={toast.className}
              withTitle={toast.withTitle}
              title={toast.title}
              linkTitle={toast.linkTitle}
              url={toast.url}
              titleClassName={toast.titleClassName}
              messageClassName={toast.messageClassName}
              linkClassName={toast.linkClassName}
              iconSize={toast.iconSize}
              withTitleIconClass={toast.withTitleIconClass}
              linkIconSize={toast.linkIconSize}
            />
          ))}
        {children}
      </ToastContext.Provider>
    </>
  );
}
