import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/client-root.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@akinon/next/components/pz-providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebVitals"] */ "/app/node_modules/@akinon/next/components/web-vitals.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/[commerce]/[locale]/[currency]/layout.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"fallback\":[\"ui-sans-serif\",\"system-ui\",\"sans-serif\",\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\",\"Noto Color Emoji\"],\"variable\":\"--font-montserrat\"}],\"variableName\":\"montserratFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/[commerce]/[locale]/[currency]/client-root.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["ToastProvider"] */ "/app/src/components/toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/views/root-modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/widgets/cookie-modal/index.tsx");
