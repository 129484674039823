import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TInitialState = {
  agreement: boolean;
};

const InitialState: TInitialState = {
  agreement: false
};

const checkoutSlice = createSlice({
  name: 'checkoutAgreement',
  initialState: InitialState,
  reducers: {
    setAgreement: (state, action: PayloadAction<boolean>) => {
      state.agreement = action.payload;
    }
  }
});

export const { setAgreement } = checkoutSlice.actions;

export const selectAgreement = (state: any) => state.checkoutAgreement;

export default checkoutSlice.reducer;
