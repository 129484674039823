'use client';
import { CookiePermissionType } from './type';
import { Button, Icon, Link } from '@theme/components';
import { useEffect, useState } from 'react';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { CookieIcon } from '@theme/components/icons/cookie-icon';

export default function CookiePermissionModal() {
  const { data } = useGetWidgetQuery<CookiePermissionType>('cookie-permission');

  const [accepted, setAccepted] = useState<boolean>(false);
  const [isClosed, setIsClosed] = useState<boolean>(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookieAccepted');
    if (cookieAccepted) {
      setAccepted(true);
    }
  }, []);

  const attributes = data?.attributes;

  if (attributes?.cookie_active?.value === 'false') {
    return null;
  }

  if (accepted || isClosed || Object.keys(attributes || {}).length === 0)
    return null;

  const handleClick = () => {
    localStorage.setItem('cookieAccepted', 'true');
    setAccepted(true);
  };

  const handleClose = () => {
    setIsClosed(true);
  };

  return (
    <div className="fixed bottom-0 z-50 flex w-full flex-col bg-gray-100 sm:h-40">
      <div className="flex w-full flex-row items-center justify-between border-b border-[#d6e0e5] px-4 pb-[17px] pt-[26px] sm:px-[72px] sm:pt-[24px]">
        <div className="flex flex-row items-center justify-center gap-1">
          <CookieIcon className="text-center" size={32} />
          <p className="text-lg font-medium">
            {data?.attributes?.cookie?.value?.cookie_title}
          </p>
        </div>
        <Icon
          onClick={handleClose}
          className="cursor-pointer"
          name="close"
          size={32}
        />
      </div>
      <div className="mb-6 mt-4 flex w-full flex-col items-center justify-between gap-4 px-4 sm:flex-row sm:px-[72px]">
        <div className="text-center">
          {data?.attributes?.cookie?.value?.cookie_description}{' '}
          <Link href="/cerezler">
            <span className="font-medium underline">
              {data?.attributes?.cookie?.value?.cookie_link}
            </span>
          </Link>
        </div>
        <Button
          className="w-full max-w-[343px] sm:h-10 sm:w-[120px]"
          onClick={handleClick}
        >
          {data?.attributes?.cookie?.value?.cookie_button_text}
        </Button>
      </div>
    </div>
  );
}
