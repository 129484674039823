export function CookieIcon({ ...props }) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#eligduphra)"
        stroke="#C69C6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M12.444 16.889v.008M16 20.445v.008M16 16v.008M19.556 17.778v.008M15.111 12.444v.009M17.02 8.423l2.371.981a3.556 3.556 0 0 0 4.139 5.458l.047.118c.27.653.27 1.387 0 2.04-.442.7-.745 1.248-.91 1.647-.168.405-.364 1.061-.587 1.97a2.666 2.666 0 0 1-1.444 1.442c-.931.234-1.588.43-1.97.588-.422.175-.97.478-1.646.91-.653.27-1.387.27-2.04 0-.713-.447-1.262-.75-1.647-.91-.418-.174-1.075-.37-1.97-.587a2.666 2.666 0 0 1-1.442-1.444c-.236-.935-.431-1.59-.588-1.97-.176-.425-.48-.974-.91-1.646a2.666 2.666 0 0 1 0-2.04c.427-.662.729-1.21.91-1.647.152-.367.348-1.024.587-1.97.27-.653.79-1.172 1.444-1.442.917-.228 1.573-.423 1.97-.588.406-.169.955-.472 1.646-.91a2.666 2.666 0 0 1 2.04 0v0z"></path>
      </g>
    </svg>
  );
}
