'use client';
import { createSlice } from '@reduxjs/toolkit';

export interface FilterState {
  toggleActive: boolean;
  cityName: string;
}

const initialState: FilterState = {
  toggleActive: false,
  cityName: ''
};

const filterSlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setToggleActive(state, action) {
      state.toggleActive = action.payload;
    },
    setCityName(state, action) {
      state.cityName = action.payload;
    }
  }
});

export const { setToggleActive, setCityName } = filterSlice.actions;

export default filterSlice.reducer;
